import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import StoreContext from '../../store'
import "./switchlang.scss"

const SwitchLang = () => {

    const store = useContext(StoreContext)
    const {language,setLang} = store

  return (
    <Form className='sw-form'> 
        <Form.Label className='sw-label'>{language === "tr" ? "Dil Seçiniz" : "Select Language"}</Form.Label>
        <Form.Check // prettier-ignore
      className='sw-check'
      type="switch"
      id="custom-switch"
      label={language === "tr" ? "TR" : "EN"}
      onChange={() => setLang(language === "tr" ? "en" : "tr")}
    />
  </Form>
  )
}

export default SwitchLang
