import React, { useEffect } from 'react'
import PortfolioCard from '../../components/portfolio-card/PortfolioCard'
import { Col, Container, Row } from 'react-bootstrap'
import portfolioData from "../../data/portfolio.json"
import "./portfolio.scss"

const Portfolio = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <Container className='portfolio'>
      <Row>
        {
          portfolioData.map((item, index) => ( 
            <Col sm={12} md={6} lg={4} className='g-4' key={index}>
              <PortfolioCard item={item} />
            </Col>
          ))
        }
      </Row>
    </Container>
  )
}

export default Portfolio
