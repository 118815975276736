
import { useEffect } from 'react'
import HomepageInfo from '../../components/home-page-content/HomepageInfo'
import "./homepage.scss"
const Homepage = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div className='homepage'>
     
      <HomepageInfo/>
     
    </div>
  )
}

export default Homepage


