import React from 'react'
import { Container } from 'react-bootstrap'
import Logo from '../logo/Logo'
import NavMenu from '../links/NavMenu'
import "./footer.scss"

const Footer = () => {
  return (
    <div className='footer'>
      <Container className='footer-container'>
        <div className='footer-top'>
        <Logo/>
        <NavMenu/>
        </div>
        <span className='copy'>© 2023 Bahattin Berkdinc</span>
      </Container>
    </div>
  )
}

export default Footer
