
import Header from "./components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/home-page/Homepage";
import Footer from "./components/footer/Footer";
import Landing from "./components/landing/Landing";
import { useEffect, useState } from "react";
import Projects from "./pages/projects-page/Projects";
import About from "./pages/about-page/About.jsx"
import StoreContext from "./store";
import Portfolio from "./pages/portfolio-page/Portfolio";
import ContactPage from "./pages/contact-page/ContactPage";

function App() {
  const [isLoading,setIsLoading] = useState(false)
  const [language,setLang] = useState("en")
  useEffect(() => {
      setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout); 
    };
  }, []);

  return (
    <StoreContext.Provider value={{language,setLang}}>
<div className="App">
     {
       isLoading ? <Landing/> : (
         <>
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
        </>
      )
    }
    </div>

    </StoreContext.Provider>
    
  );
}

export default App;
