import React from 'react'
import "./logo.scss"
import { Link } from 'react-router-dom'
const Logo = () => {
  return (
    <Link to='/'  className='logo text-decoration-none'>
      <div className="logo-top">
      <span>B</span>
      <span>BERKDINC</span>
      </div>
      <div className="logo-bottom">
        <span>frontend developer</span>
      </div>
    </Link>
  )
}

export default Logo
