import React, { useContext } from 'react'
import ContactForm from '../../components/contact/ContactForm'
import { Col, Container, Row } from 'react-bootstrap'
import StoreContext from '../../store'
import MyInfo from '../../components/my-info/MyInfo'


const ContactPage = () => {

    const store = useContext(StoreContext)

  return (
    <Container className='py-5 contact-page'>
      <Row>
        <Col sm={12} md={6} className='my-info-container'>
        <MyInfo/>
        </Col>
        <Col sm={12} md={6}>
        <ContactForm store={store}/>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactPage
