import React, { useContext } from 'react'
import "./projectcard.scss"
import  StoreContext  from "../../store/index"
const ProjectCard = ({item}) => {

  const store = useContext(StoreContext)

  const {language} = store


  return (
    <div className="project-card"
    style={
      {flexDirection: item.id % 2 === 0 ? "row-reverse " : "row",
      animation: item.id % 2 === 0 ? "leftToRight 2s ease-in-out 1 forwards" : "rightToLeft 2s ease-in-out 1 forwards"
    }
    }
    >
      <div className="images-box">
      <div className="image-box-1">
        <a href={require(`../../assets/image/${item.pcImg}`)} target='_blank' rel="noreferrer">
        <img src={require(`../../assets/image/${item.pcImg}`)} alt="" />
        </a>
      </div>
      <div className="image-box-2">
       <a href={require(`../../assets/image/${item.mobileImg}`)} target='_blank' rel="noreferrer" >
       <img src={require(`../../assets/image/${item.mobileImg}`)} alt="" />
       </a>
      </div>
      </div>
      <div className="project-info">
        <h3>{item.name}</h3>
        <p>{language === "tr" ? item.descTr : item.descEn}</p>
        <a href={item.site_url} target="_blank" rel="noreferrer">{language === "tr" ? "Siteye Git" : "Go To Site"}</a>
      </div>
    </div>
  )
}

export default ProjectCard
