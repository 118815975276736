import React from 'react'
import {BsChevronLeft,BsChevronRight} from 'react-icons/bs' 
import "./landing.scss"
const Landing = () => {
  return (
    <div className="landing">
        <div className="left">
            <BsChevronLeft/>
        </div>
        <div className="right">
            <span>/</span><BsChevronRight/>
        </div>
    </div>
  )
}

export default Landing
