import React, { useContext } from 'react'
import "./portfoliocard.scss"
import folderTop from "../../assets/image/folder_top_golden_blur.png"
import folderBottom from "../../assets/image/folder_bottom_golden.png"
import { Link } from 'react-router-dom'
import StoreContext from '../../store'
const PortfolioCard = ({item}) => {

  const store = useContext(StoreContext);

  const {language} = store

    console.log(item);

  return (
    <div className='portfolio-card' style={{backgroundImage:`url(${require(`../../assets/image/${item.pcImg}`)})`}}>
      <div className="folder">
        <div className="folder-top">
            <img src={folderTop} alt="" />
        </div>
      <span>{item.name}</span>
        <div className="folder-bottom">
            <img src={folderBottom} alt="" />
            <Link to={item.site_url} className="button" target='_blank'>{language === "tr" ? "SİTEYE GİT" : "GO TO SITE"}</Link>
        </div>
        <div className="clone-images">
            <img src={require(`../../assets/image/${item.pcImg}`)} alt="" />
            <img src={require(`../../assets/image/${item.mobileImg}`)} alt="" />
        </div>
      </div>
    </div>
  )
}

export default PortfolioCard
