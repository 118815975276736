import Swal from 'sweetalert2'


const TostMessage = () => {
    Swal.fire({
        position: 'top-center',
        icon: 'success',
        title: 'Mesajınız iletildi!',
        showConfirmButton: false,
        timer: 1500
      })
}


export default TostMessage
