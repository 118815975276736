import React from 'react'
import "./header.scss"
import Logo from '../logo/Logo'
import NavMenu from '../links/NavMenu'
import { Container } from 'react-bootstrap'
import SwitchLang from '../switchLang/SwitchLang'
const Header = () => {
  return (
    <header className='header'>
      <Container className='header-container h'>
      <Logo/>
      <NavMenu/>
      <SwitchLang/>
      </Container>
    </header>
  )
}

export default Header
