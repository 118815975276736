import React from 'react'

import { SocialIcon } from 'react-social-icons';
import "./myinfo.scss"
const MyInfo = () => {
  return (
    <div className='my-info'> 
        <div className="social">
                <SocialIcon url="https://twitter.com/bberkdinc" target='_blank'/>
                <SocialIcon url="https://instagram.com/bahattinberkdinc" target='_blank'/>
                <SocialIcon bgColor='white' url="https://github.com/bahattinberkdinc" target='_blank' />
                <SocialIcon url="https://linkedin.com/in/bahattinberkdinc" target='_blank'/>
                <SocialIcon bgColor='white' url="https://medium.com/@bberkdinc" target='_blank'/>
         </div>
    </div>
  )
}

export default MyInfo
