import React, { useContext, useState } from 'react'
import { Navbar, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {RxHamburgerMenu} from "react-icons/rx"
import {BsChevronDoubleRight} from "react-icons/bs"
import "./navmenu.scss"
import StoreContext from '../../store'
import Logo from '../logo/Logo'
import SwitchLang from '../switchLang/SwitchLang'
const NavMenu = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const store = useContext(StoreContext)
  const {language} = store

  return (
<>
<Navbar className='navbar nav-main'>
      <Link className='nav-links' to="/" >{language === "tr" ? "Anasayfa" : "Home"}</Link>
      <Link className='nav-links' to="/about">{language === "tr" ? "Hakkımda" : "About"}</Link>
      <Link className='nav-links' to="/projects">{language === "tr" ? "Projeler" : "Projects"}</Link>
      <Link className='nav-links' to="/portfolio">{language === "tr" ? "Portfolyo" : "Portfolio"}</Link>
      <Link className='nav-links' to="/contact">{language === "tr" ? "İletişim" : "Contact"}</Link>
    </Navbar>

<div className='offcanvas-menu'>
<Link className='canvas-btn' onClick={handleShow}>
  <RxHamburgerMenu/>
</Link>

<Offcanvas className='offcanvas' show={show} onHide={handleClose}>
  <Offcanvas.Header closeButton>
    <Offcanvas.Title><Logo/></Offcanvas.Title>
  </Offcanvas.Header>
  <Offcanvas.Body>
  <Navbar className='navbar-canvas align-items-start'>
      <Link className='nav-links' onClick={handleClose} to="/" >{language === "tr" ? "Anasayfa" : "Home"} <BsChevronDoubleRight/></Link>
      <Link className='nav-links' onClick={handleClose}  to="/about">{language === "tr" ? "Hakkımda" : "About"} <BsChevronDoubleRight/></Link>
      <Link className='nav-links' onClick={handleClose}  to="/projects">{language === "tr" ? "Projeler" : "Projects"} <BsChevronDoubleRight/></Link>
      <Link className='nav-links' onClick={handleClose}  to="/portfolio">{language === "tr" ? "Portfolyo" : "Portfolio"} <BsChevronDoubleRight/></Link>
      <Link className='nav-links' onClick={handleClose} to="/contact">{language === "tr" ? "İletişim" : "Contact"} <BsChevronDoubleRight/></Link>
    </Navbar>
    <SwitchLang/>
  </Offcanvas.Body>
</Offcanvas>
</div>
</>

  )
}

export default NavMenu
