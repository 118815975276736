import React, { useContext } from 'react'
import {Col, Container, Row } from 'react-bootstrap'
import { SocialIcon } from 'react-social-icons';
import photo from "../../assets/image/linkednProfilePic.png"
import "./homepageinfo.scss"
import { Link } from 'react-router-dom';
import StoreContext from '../../store';

const HomepageInfo = () => {

    const store = useContext(StoreContext)

    const {language} = store


  return (
    <Container>
        <Row className='info'>
            <Col sm={12} md={6}>
                <div className="info-top">
                <h1>{language === "tr" ? "Merhaba !" : "Hi !"}</h1>
                <h2>{language === "tr" ? "Ben" : "I am"} <span>{language === "tr" ? "Bahattin" : "Berkdinc"}</span></h2>
                <h3>
                    frontend developer
                </h3>
                <Link className="button" to="/projects">{language === "tr" ? "Projeleri Gör" : "Show Projects"}</Link>

                <div className="social my-5 d-flex gap-3">
                <SocialIcon url="https://twitter.com/bberkdinc" target='_blank'/>
                <SocialIcon url="https://instagram.com/bahattinberkdinc" target='_blank'/>
                <SocialIcon bgColor='white' url="https://github.com/bahattinberkdinc" target='_blank' />
                <SocialIcon url="https://linkedin.com/in/bahattinberkdinc" target='_blank'/>
                <SocialIcon bgColor='white' url="https://medium.com/@bberkdinc" target='_blank'/>
                </div>
                </div>
            </Col>
            <Col  sm={12} md={6}>
                <div className="info-image">
                    <img src={photo} alt="" />
                    <div className='white'>
                        <div className="box"></div>
                    </div>
                    <div className='golden'>
                        <div className="box2"></div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default HomepageInfo
