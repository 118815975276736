import React from 'react'
import "./tooliuse.scss"
const ToolsIUse = () => {
  return (
    <div className='toolsIUse'>
      <div className="images-icons">
      <img src={require("../../assets/icons/317755_badge_html_html5_achievement_award_icon.png")} alt="" />
      <img src={require("../../assets/icons/317756_badge_css_css3_achievement_award_icon.png")} alt="" />
      <img src={require("../../assets/icons/4373213_js_logo_logos_icon.png")} alt="" />
      <img src={require("../../assets/icons/4375066_logo_sass_icon.png")} alt="" />
      <img src={require("../../assets/icons/bootstrap-logo-shadow.png")} alt="" />
      <img src={require("../../assets/icons/pngwing.com.png")} alt="" />
      <img src={require("../../assets/icons/react.png")} alt="" />
      <img src={require("../../assets/icons/redux.png")} alt="" />
      <img src={require("../../assets/icons/177079_client_filezilla_icon.png")} alt="" />
      <img  src={require("../../assets/icons/aws.png")} alt="" />
      </div>
    </div>
  )
}

export default ToolsIUse
