import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import emailjs from '@emailjs/browser';
import "./contactform.scss"
import TostMessage from '../../functions/sAlert';
const ContactForm = ({store}) => {

  const {language} = store

  const isFormValid = () => {
    return (
      formData.name !== '' &&
      formData.lastname !== '' &&
      formData.email !== '' &&
      formData.subject !== '' &&
      formData.message !== ''
    );
  };

  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      lastname: formData.lastname,
      email: formData.email,
      subject: formData.subject,
      message: formData.message
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        TostMessage();
        setFormData({
          name: '',
          lastname: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('FAILED...', error);
      });
  };



  return (
    <Form className='contact-form text-white' onSubmit={sendEmail}>
      <h1>{language === "tr" ? "İletişim" : "Contact"}</h1>
      <span>{language === "tr" ? "Merak ettiğiniz bir şey mi var? Projeleriniz,  önerileriniz mi var? Benimle iletişime geçmekten çekinmeyin!  Sizden haber almak ve yanıtlamak için sabırsızlanıyor, mutluluk duyuyorum." : "Is there something you're curious about? Do you have projects or suggestions in mind? Don't hesitate to get in touch with me! I'm eager to hear from you and respond. Looking forward to it with excitement!"}</span>
      <Form.Group className="mb-3 d-flex justify-content-between gap-5" >
        <div className='w-100'>
        <Form.Control type="text" 
        placeholder={language === "tr" ? "Isim" : " Surname"} 
        name='name'
        value={formData.name}
        onChange={handleInputChange}
        />
        </div>
        <div className='w-100'>
        <Form.Control type="text" 
        placeholder={language === "tr" ? "Soyisim" : "Lastname"} 
        name='lastname' 
        value={formData.lastname}
        onChange={handleInputChange}
        />
        </div>
      </Form.Group>
     
    
      <Form.Group className="mb-3" >
        <Form.Control type="text" 
        placeholder={language === "tr" ? "Konu" : "Subject"} 
        name='subject' 
        value={formData.subject}
        onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" >
        <Form.Control type="email" 
        placeholder="name@example.com" 
        name='email' 
        value={formData.email}
        onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control as="textarea"rows={3} style={{ height: "250px" }} name='message'
        value={formData.message}
        onChange={handleInputChange}
        />
      </Form.Group>
      <Button
  type="submit"
  className="button"
  disabled={!isFormValid()}
>
  {language === "tr" ? "Gönder" : "Send"}
</Button>

    </Form>
  )
}

export default ContactForm
