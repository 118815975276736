import React, { useEffect } from 'react'
import ProjectCard from '../../components/project-card/ProjectCard'
import data from "../../data/data.json"
import { Col, Container, Row } from 'react-bootstrap'
const Projects = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <Container className='py-5'>
      <Row>
      {
        data.map((item,index)=>(
          <Col sm={12} key={index} className='gy-5'>
          <ProjectCard  item={item}/>
          </Col>
        ))
      }
      </Row>
      
    </Container>
  )
}

export default Projects
