import React, { useContext, useEffect } from 'react'
import "./about.scss"
import photo from '../../assets/image/linkednProfilePic.png'
import { Container } from 'react-bootstrap'
import aboutData from "../../data/about.json"
import ToolsIUse from '../../components/tools-comp/ToolsIUse'
import StoreContext from '../../store'
const About = () => {

  const store = useContext(StoreContext)

  const trParagraphs = aboutData[0].descTr.map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));
  const enParagraphs = aboutData[1].descEn.map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));

  useEffect(() => {
    window.scrollTo(0, 0)
  })


  return (
    <Container>
      <div className="about text-white">
      <div className="image">
        <img src={photo} alt="" />
      </div>
      <div className="info">
      <span>{store.language === "tr" ? trParagraphs : enParagraphs}</span>
      <span>
      Bahattin Berkdinc
      </span>
   
      </div>
    </div>
    <ToolsIUse/>
    </Container>
  )
}

export default About
